$fontFamily: 'Montserrat',
sans-serif;

body {
  font-family: $fontFamily;
}

#modal-root {
  position: relative;
  z-index: 999;
}

.zdfield {
  top: -5px;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  position: absolute;
  transition: padding-left 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, border-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  pointer-events: none;
}

.content-autocomplete {
  margin-top: 16px;

  &__input {
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 18.5px 14px;
    color: currentColor;
    height: 1.1875em;
    font-size: 16px;
    width: 100%;
    background: transparent;
    font-family: $fontFamily;

    &::-webkit-input-placeholder,
    &:-ms-input-placeholder,
    &::placeholder {
      font-family: $fontFamily;
      color: #263238;
    }

    &:focus {
      outline: #3f51b5 auto 0;
      border-color: #3f51b5;
      border-width: 2px;
    }
  }
}

.autocomplete-dropdown-container {
  &__loading {
    background: #e6e6e6;
    padding: 10px;
    font-family: $fontFamily;
  }
}

.suggestion-item {

  span {
    display: block;
    background: #e6e6e6;
    padding: 10px;
    font-family: $fontFamily;
  }

  &--active {
    span {
      display: block;
      padding: 10px;
      background: #ccc;
      font-family: $fontFamily;
      cursor: pointer;
    }
  }
}

//select
.multiple-select {
  color: #263238 !important;

  [class$="control"] {
    padding: .32em 4px;
    font-family: $fontFamily;
    font-size: 13px;
    color: #263238;

    >div {
      [class$="placeholder"] {
        color: #263238;
      }

      padding: 4px;
      ;
    }
  }

  [class$="menu"] {
    font-family: $fontFamily;
    color: #263238;
    z-index: 999;
  }
}

.main-container {
  background-position: center;
  background-size: cover;

  &.list-offers {
    background-image: url('/images/offers.png');
  }
}